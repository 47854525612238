import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { ThemeOptions } from '@mui/material/styles';
import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';
import { paletteColorTypes } from '../Palette/paletteColorTypes';
import { ThemeOverride } from './ThemeOverride';
import { ThemePaletteOverride } from './ThemePaletteOverride';

/**
 * Props for the ThemeOverridesCardContent component.
 */
export interface ThemeOverridesCardContentProps {
  /**
   * Overrides for the theme options.
   */
  themeOverrides?: ThemeOptions;
  /**
   * The base theme to display.
   */
  baseTheme: ReactNode;
  /**
   * Function to open edit theme dialog.
   */
  onEdit: () => void;
}

/**
 * Renders a card with a list of theme overrides for a given theme.
 * @returns  The rendered component.
 */
export function ThemeOverridesCardContent({
  themeOverrides,
  baseTheme,
  onEdit,
}: ThemeOverridesCardContentProps) {
  const { t } = useTranslation();
  const themeOverridesNodes: [string, ReactNode][] = [];

  themeOverridesNodes.push([
    'baseTheme',
    <ThemeOverride label={t('Base Theme')}>{baseTheme}</ThemeOverride>,
  ]);
  themeOverridesNodes.push([
    'logo',
    <ThemeOverride label={t('Logo')}>
      {themeOverrides?.katsomo?.header?.logo?.filename}
    </ThemeOverride>,
  ]);
  themeOverridesNodes.push([
    'header-height',
    <ThemeOverride label={t('Header height')}>
      {themeOverrides?.mixins?.toolbar?.minHeight}
    </ThemeOverride>,
  ]);
  themeOverridesNodes.push([
    'palette.mode',
    <ThemeOverride label={t('Color mode')}>
      {themeOverrides?.palette?.mode}
    </ThemeOverride>,
  ]);
  themeOverridesNodes.push([
    'headerColorOverride',
    <ThemePaletteOverride
      label={t('Header Color Override')}
      path="components.MuiAppBar.styleOverrides.colorPrimary"
      paletteValues={[
        ['Background', 'backgroundColor'],
        ['Text', 'color'],
      ]}
      theme={themeOverrides}
    />,
  ]);
  themeOverridesNodes.push([
    'backgroundImage',
    <ThemeOverride label={t('Background Image')}>
      {themeOverrides?.katsomo?.backgroundImage?.image?.filename}
    </ThemeOverride>,
  ]);
  themeOverridesNodes.push([
    'backgroundBlendMode',
    <ThemeOverride label={t('Background Blend Mode')}>
      {themeOverrides?.katsomo?.backgroundBlendMode}
    </ThemeOverride>,
  ]);
  themeOverridesNodes.push([
    'background',
    <ThemePaletteOverride
      label={t('Background')}
      path="palette.background"
      paletteValues={[
        ['Default', 'default'],
        ['Paper', 'paper'],
      ]}
      theme={themeOverrides}
    />,
  ]);

  const colors = paletteColorTypes.map(
    (colorType) =>
      [
        colorType,
        <ThemePaletteOverride
          label={colorType}
          path={`palette.${colorType}`}
          paletteValues={[
            ['Main', `main`],
            ['Light', `light`],
            ['Dark', `dark`],
            ['Contrast Text', `contrastText`],
          ]}
          theme={themeOverrides}
        />,
      ] as [string, ReactNode],
  );

  themeOverridesNodes.push(...colors);

  themeOverridesNodes.push([
    'divider',
    <ThemePaletteOverride
      label={t('Divider')}
      path="palette"
      paletteValues={[['Divider', 'divider']]}
      theme={themeOverrides}
    />,
  ]);

  themeOverridesNodes.push([
    'fonts',
    <ThemeOverride label={t('Fonts')}>
      {themeOverrides?.customFonts?.join(', ')}
    </ThemeOverride>,
  ]);

  const typography =
    typeof themeOverrides?.typography === 'object'
      ? themeOverrides.typography
      : undefined;

  themeOverridesNodes.push([
    'fontFamily',
    <ThemeOverride label={t('fontFamily')}>
      {typography?.fontFamily}
    </ThemeOverride>,
  ]);

  return (
    <Card aria-labelledby="theme-card-header">
      <CardHeader title={t('Theme')} id="theme-card-header" />
      <CardContent>
        {themeOverridesNodes.map(([key, value]) => (
          <Box
            key={key}
            sx={{ display: 'flex', gap: 1, alignItems: 'baseline' }}
          >
            {value}
          </Box>
        ))}
      </CardContent>
      <CardActions>
        <Button size="small" onClick={onEdit}>
          {t('Edit')}
        </Button>
      </CardActions>
    </Card>
  );
}

export default ThemeOverridesCardContent;
