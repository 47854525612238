import { EventDoc } from '@livekatsomo/models';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';
import { EventsPreviewSwiper } from './EventsPreviewSwiper';

/**
 * Props for the EventsPreview component
 */
export interface EventsPreviewProps {
  /**
   * The title of the events preview
   */
  title?: ReactNode;
  /**
   * An array of EventDoc objects to display in the preview
   */
  events?: EventDoc[];
}

/**
 * A component that displays a preview of upcoming events
 * @param {EventsPreviewProps} props - The props for the component
 * @returns The rendered component
 */
export function EventsPreview({ title, events }: EventsPreviewProps) {
  if (!events || events.length === 0) return null;

  return (
    <>
      {typeof title === 'string' ? (
        <Typography
          variant="h4"
          component="h2"
          sx={{
            alignSelf: 'flex-start',
          }}
        >
          {title}
        </Typography>
      ) : (
        title
      )}
      <Box sx={{ width: '100%' }}>
        <EventsPreviewSwiper events={events} />
      </Box>
    </>
  );
}

export default EventsPreview;
