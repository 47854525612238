/**
 * Sorts an array of objects by their `startDate` property in ascending order.
 * @param a - The first object to compare.
 * @param b - The second object to compare.
 * @returns A negative number if `a` should be sorted before `b`, a positive number if `a` should be sorted after `b`, or 0 if they are equal.
 */
export function sortByStartDateAscending(
  a: { startDate: Date },
  b: { startDate: Date },
): number {
  return a.startDate.getTime() - b.startDate.getTime();
}

/**
 * Sorts an array of objects by their `startDate` property in descending order.
 * @param a - The first object to compare.
 * @param b - The second object to compare.
 * @returns A negative number if `a` should be sorted before `b`, a positive number if `a` should be sorted after `b`, or 0 if they are equal.
 */
export function sortByStartDateDescending(
  a: { startDate: Date },
  b: { startDate: Date },
): number {
  return b.startDate.getTime() - a.startDate.getTime();
}
