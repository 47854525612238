import { DevTool } from '@hookform/devtools';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  AssetDoc,
  CustomerDoc,
  EventDoc,
  EventFormValues,
  assetInDocumentSchema,
  eventSchema,
  imageFileSchema,
} from '@livekatsomo/models';
import {
  FileUploadProgess,
  UploadProgressFunction,
  ValidateEventSlugFunction,
} from '@livekatsomo/types';
import { useEnvironment } from '@livekatsomo/web/hooks';
import {
  AutoCompleteField,
  DateTimePickerField,
  ImageUploadButtonField,
  RadioField,
  SelectField,
  SlugField,
  SwitchField,
  TextField,
  MultipleSelectField,
} from '@livekatsomo/web/ui-components/react-hook-form-components';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { z } from 'zod';

/**
 * The schema for the event form data.
 */
const formSchema = eventSchema
  .pick({
    name: true,
    description: true,
    customerId: true,
    slug: true,
    visibility: true,
    vodEnabled: true,
    demoEvent: true,
    categories: true,
  })
  .extend({
    startDate: z.coerce
      .date()
      .max(new Date('2100-10-10'), 'Start date must be before 2100'),
    endDate: z.coerce
      .date()
      .max(new Date('2100-10-10'), 'Start date must be before 2100'),
    vodExpiryDate: z.coerce
      .date()
      .max(new Date('2100-10-10'), 'Start date must be before 2100')
      .optional(),
    poster: imageFileSchema.or(assetInDocumentSchema),
  })
  .refine(
    (data) => {
      return data.endDate.getTime() > data.startDate.getTime();
    },

    {
      params: {
        i18n: { key: 'End date must be after start date' },
      },
      path: ['endDate'],
    },
  )
  .refine(
    (data) => {
      if (!data.vodEnabled) {
        return true;
      }
      return (
        data.vodExpiryDate &&
        data.vodExpiryDate.getTime() > data.endDate.getTime()
      );
    },
    {
      params: {
        i18n: { key: 'VOD expiry date must be after end date' },
      },
      path: ['vodExpiryDate'],
    },
  );

export interface EventFormProps {
  /**
   * An array of assets to choose from for the event poster.
   */
  assets?: AssetDoc[];
  /**
   * The event being edited, if any.
   */
  event?: EventDoc;
  /**
   * The customer associated with the event, if any.
   */
  customer?: CustomerDoc;
  /**
   * An array of customers to choose from for the event.
   */
  customers?: CustomerDoc[] | null;
  /**
   * A function to call when the form is closed.
   */
  onClose: () => void;
  /**
   * A function to validate the event slug.
   */
  onValidateSlug: ValidateEventSlugFunction;
  /**
   * A function to call when the form is submitted.
   */
  onSubmit: (
    customer: EventFormValues,
    setProgress?: UploadProgressFunction,
  ) => Promise<void>;
}

/**
 * A form component for creating or editing an event.
 * @returns The event form component.
 */
export function EventForm({
  event,
  assets,
  customer,
  customers,
  onClose,
  onSubmit,
  onValidateSlug,
}: EventFormProps) {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [uploadProgress, setProgress] = useState<FileUploadProgess>({});
  const { development } = useEnvironment();
  const { t } = useTranslation();

  const initialValues = useMemo(
    () => ({
      customerId: customer?.id || '',
      name: '',
      description: '',
      slug: '',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      startDate: null as any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      endDate: null as any,
      visibility: 'private',
      poster: '',
      vodEnabled: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      vodExpiryDate: null as any,
      sendgridTemplateId: '',
      demoEvent: false,
      categories: event?.categories || [],
      ...event,
    }),
    [customer, event],
  );

  const methods = useForm({
    resolver: async (data, context, options) => {
      const validationStatus = await zodResolver(formSchema)(
        data,
        context,
        options,
      );
      console.log('validationStatus', validationStatus, data);
      return validationStatus;
    },
    defaultValues: initialValues,
    mode: 'all',
  });

  const handleClose = () => {
    setErrorMessage(undefined);
    setProgress({});
    onClose();
  };

  const submit = async (values: typeof initialValues) => {
    console.log('event form submitted', values);

    try {
      const validatedValues = await formSchema.parseAsync(values);
      await onSubmit(validatedValues, setProgress);
      handleClose();
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  };

  const {
    formState: { isDirty, isSubmitting, isValid },
    watch,
    handleSubmit,
    reset,
    setValue,
    control,
  } = methods;

  const [startDate, endDate, vodEnabled] = watch([
    'startDate',
    'endDate',
    'vodEnabled',
  ]);

  const [customerId, poster] = watch(['customerId', 'poster']);

  const handleValidateSlug = useCallback(
    (slug: string) =>
      customerId
        ? onValidateSlug(customerId, slug, event)
        : Promise.resolve(undefined),
    [onValidateSlug, customerId, event],
  );

  const visibility = useWatch({ control, name: 'visibility' });

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(submit)}
        onReset={() => reset(initialValues)}
      >
        <DevTool control={control} />
        <DialogContent>
          <DialogContentText>{t('Add event details here.')}</DialogContentText>
          {assets?.length ? (
            <SelectField
              options={assets}
              required={!poster}
              name="poster"
              label={t('Poster')}
              variant="standard"
              fullWidth
              optionViewer={(asset) => asset.filename}
            />
          ) : null}
          <ImageUploadButtonField
            uploadProgress={uploadProgress}
            required
            name="poster"
            label={t('Upload Poster')}
          />
          <TextField
            autoFocus={!!customer?.id}
            margin="dense"
            name="name"
            required
            label={t('name')}
            type="text"
            fullWidth
            variant="standard"
          />
          {!customer?.id && customers ? (
            <AutoCompleteField
              margin="dense"
              name="customerId"
              required
              autoFocus
              label={t('customer')}
              variant="standard"
              fullWidth
              options={customers}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            />
          ) : null}
          <TextField
            margin="dense"
            name="description"
            label={t('description')}
            type="text"
            fullWidth
            required
            variant="standard"
          />
          <DateTimePickerField
            name="startDate"
            label={t('start date')}
            maxDateTime={endDate}
            fullWidth
            required
            helperText={t(
              'Track and agenda item start times are relative to this',
            )}
            slotProps={{
              textField: {
                variant: 'standard',
                margin: 'dense',
              },
            }}
          />
          <DateTimePickerField
            name="endDate"
            label={t('end date')}
            minDateTime={startDate}
            fullWidth
            required={true}
            slotProps={{
              textField: {
                variant: 'standard',
                margin: 'dense',
              },
            }}
          />
          <SlugField
            name="slug"
            margin="dense"
            source="name"
            validate={handleValidateSlug}
            label={t('slug')}
            type="text"
            fullWidth
            required
            variant="standard"
          />
          <RadioField
            name="visibility"
            label={t('visibility')}
            control={control}
            fullWidth
            optionViewer={(option) => t(option.label)}
            optionSelector={(option) => option.value}
            options={[
              { label: t('private'), value: 'private' },
              { label: t('channel-only'), value: 'channel-only' },
              { label: t('public'), value: 'public' },
            ]}
          />
          {visibility !== 'private' ? (
            <MultipleSelectField
              control={control}
              name="categories"
              margin="normal"
              label={t('Channel categories')}
              options={customer?.categories || []}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            />
          ) : null}
          <SwitchField
            control={control}
            name="vodEnabled"
            label={t('VOD enabled')}
          />
          <DateTimePickerField
            name="vodExpiryDate"
            label={t('VOD expiry date')}
            control={control}
            minDateTime={endDate}
            disabled={!vodEnabled}
            fullWidth
            required={vodEnabled}
            slotProps={{
              textField: {
                variant: 'standard',
                margin: 'dense',
              },
            }}
          />
          {customer?.systemChannel ? (
            <SwitchField
              control={control}
              name="demoEvent"
              label={t('Demo event')}
            />
          ) : null}
          {errorMessage ? (
            <Typography
              color="error"
              variant="body1"
              sx={{ textAlign: 'center', mt: 2 }}
            >
              {errorMessage}
            </Typography>
          ) : null}
        </DialogContent>
        <DialogActions>
          {development ? (
            <Button
              type="button"
              onClick={async (e) => {
                // Dynamically load generateRandomSlideDeck
                const generateEventFormContent = (
                  await import(
                    '@livekatsomo/web/ui-components/random-data-generators'
                  )
                ).generateEventFormContent;
                generateEventFormContent(setValue, customer?.id, customers);
              }}
            >
              {t('Generate')}
            </Button>
          ) : null}
          <Button onClick={handleClose}>{t('Cancel')}</Button>
          <Button type="reset" disabled={isSubmitting || !isDirty}>
            {t('Reset')}
          </Button>
          <LoadingButton
            type="submit"
            disabled={!isValid || isSubmitting || !isDirty}
            loading={isSubmitting}
          >
            {event ? t('Update') : t('Add')}
          </LoadingButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
}

export default EventForm;
