import Upload from '@mui/icons-material/Upload';
import Button from '@mui/material/Button';
import { DropzoneInputProps } from 'react-dropzone';

/**
 * Props for the UploadButton component. Used with react-dropzone.
 */
export interface UploadButtonProps {
  /**
   * The label to display on the button.
   */
  label?: string;
  /**
   * Function that returns props to be spread onto the input element.
   */
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
}

/**
 * A button component that allows users to upload files by clicking on it.
 * @param label - The label to display on the button. Defaults to 'Upload'.
 * @param getInputProps - A function that returns the props to be spread on the input element.
 * @returns A React component.
 */
export function UploadButton({ label, getInputProps }: UploadButtonProps) {
  return (
    <Button component="label" endIcon={<Upload />}>
      {label || 'Upload'}
      <input {...getInputProps()} />
    </Button>
  );
}

export default UploadButton;
