import { Event } from '@livekatsomo/models';
import { OldPreviewCard } from './OldPreviewCard';
import { NewPreviewCard } from './NewPreviewCard';
import { useSubHeader } from '@livekatsomo/web/hooks';

/**
 * Props for the EventPreviewCard component.
 */
export interface EventPreviewCardProps {
  event: Event;
  type?: 'old' | 'new';
}

/**
 * Renders a preview card for an event.
 *
 * @param props - The component props.
 * @param props.event - The event to display.
 * @param [props.type='new'] - The type of preview card to display.
 * @returns The rendered component.
 */
export function EventPreviewCard({
  event,
  type = 'new',
}: EventPreviewCardProps) {
  const subHeader = useSubHeader(event);
  if (type === 'new') return <NewPreviewCard event={event}></NewPreviewCard>;
  else
    return (
      <OldPreviewCard event={event} subHeader={subHeader}></OldPreviewCard>
    );
}

export default EventPreviewCard;
