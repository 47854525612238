import { Event } from '@livekatsomo/models';
import { ThemeOverridesCardContent } from '@livekatsomo/web/ui-components/theme';
import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';

export interface EventThemeCardProps {
  /**
   * The event object containing the base theme information.
   */
  event: Event;
  /**
   * A function that will be called when the edit button is clicked.
   */
  onEdit: () => void;
}

/**
 * Renders a card displaying information about an event's theme.
 *
 * @returns The rendered component.
 */
export function EventThemeCard({ event, onEdit }: EventThemeCardProps) {
  const { t } = useTranslation();
  let baseTheme: ReactNode = '';

  if (event.baseTheme) {
    baseTheme = event.baseTheme.name;
  } else if (event.baseTheme === undefined && event.customer?.theme) {
    baseTheme = (
      <span>
        {t('Using default customer theme')}:{' '}
        <em>{event.customer.theme.name}</em>
      </span>
    );
  } else {
    baseTheme = t('Using default system theme');
  }

  console.log({ theme: event.themeOverrides });

  return (
    <ThemeOverridesCardContent
      themeOverrides={event.themeOverrides}
      baseTheme={baseTheme}
      onEdit={onEdit}
    />
  );
}

export default EventThemeCard;
