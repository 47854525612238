import { Event, EventConfigDoc } from '@livekatsomo/models';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import Box from '@mui/material/Box';
import { AuthorizationConfigItem } from './AuthorizationConfigItem';

/**
 * Component for editing event authorization settings.
 * @param event - The event to edit.
 * @param eventConfig - The event config to edit.
 * @param onUpdate - The function to call when the form is submitted.
 * @param props - The props to pass to the `Card` component.
 */
export function AuthorizationSettings({
  authorization,
  eventConfig,
  authorizationRequired,
}: {
  eventConfig?: EventConfigDoc | null;
  authorization: NonNullable<Event['authorization']>;
  authorizationRequired: boolean;
}) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '200px 1fr',
        gap: 1,
        alignItems: 'baseline',
      }}
    >
      <AuthorizationConfigItem
        label={t('Password Authorization')}
        enabled={authorization.passwordEnabled}
      >
        {authorization.passwordEnabled ? t('Enabled') : t('Disabled')}
      </AuthorizationConfigItem>
      {authorization.passwordEnabled ? (
        <AuthorizationConfigItem label={t('Password')}>
          {eventConfig?.password || t('Not set')}
        </AuthorizationConfigItem>
      ) : null}
      <AuthorizationConfigItem
        label={t('Domain Authorization')}
        enabled={authorization.domainEnabled}
      >
        {authorization.domainEnabled ? t('Enabled') : t('Disabled')}
      </AuthorizationConfigItem>
      {authorization.domainEnabled ? (
        <AuthorizationConfigItem label={t('Authorized Domains')}>
          {eventConfig?.authorizedDomains?.join(',') || t('Not set')}
        </AuthorizationConfigItem>
      ) : null}
      <AuthorizationConfigItem
        label={t('Invitations')}
        enabled={authorization.invitationsEnabled}
      >
        {authorization.invitationsEnabled ? t('Enabled') : t('Disabled')}
      </AuthorizationConfigItem>
      <AuthorizationConfigItem
        label={t('Form submission required')}
        enabled={authorization.surveyFormEnabled}
      >
        {authorization.surveyFormEnabled ? t('Enabled') : t('Disabled')}
      </AuthorizationConfigItem>
      {/* TICKET SERVICES */}
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{
          gridColumn: '1 / -1',
        }}
      >
        {t('Ticket Services')}
      </Typography>
      <AuthorizationConfigItem
        label={t('Nettilippu')}
        enabled={authorization.nettilippuEnabled}
      >
        {authorization.nettilippuEnabled ? t('Enabled') : t('Disabled')}
      </AuthorizationConfigItem>
      {authorization.nettilippuEnabled ? (
        <>
          <AuthorizationConfigItem label={t('Nettilippu Event ID')}>
            {eventConfig?.nettilippuEventId || t('Not set')}
          </AuthorizationConfigItem>
          <AuthorizationConfigItem label={t('Nettilippu Purchase URL')}>
            {authorization.purchaseUrl || t('Not set')}
          </AuthorizationConfigItem>
        </>
      ) : null}
      <AuthorizationConfigItem
        label={t('Ticketmaster')}
        enabled={authorization.ticketmasterEnabled}
      >
        {authorization.ticketmasterEnabled ? t('Enabled') : t('Disabled')}
      </AuthorizationConfigItem>
      {authorization.ticketmasterEnabled ? (
        <>
          <AuthorizationConfigItem label={t('Ticketmaster Event ID')}>
            {eventConfig?.ticketmasterEventId || t('Not set')}
          </AuthorizationConfigItem>
          <AuthorizationConfigItem label={t('Ticketmaster Purchase URL')}>
            {authorization.purchaseUrl || t('Not set')}
          </AuthorizationConfigItem>
        </>
      ) : null}
      {authorizationRequired ? (
        <>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              gridColumn: '1 / -1',
            }}
          >
            {t('Additional Settings')}
          </Typography>
          <AuthorizationConfigItem
            label={t('Anonymous users allowed')}
            enabled={authorization.anonymousUsersEnabled}
          >
            {authorization.anonymousUsersEnabled ? t('Enabled') : t('Disabled')}
          </AuthorizationConfigItem>
          <AuthorizationConfigItem
            label={t('Hide login and registration in authorization')}
            enabled={authorization.hideLoginAndRegister}
          >
            {authorization.hideLoginAndRegister ? t('Enabled') : t('Disabled')}
          </AuthorizationConfigItem>
        </>
      ) : null}
    </Box>
  );
}
