import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'next-i18next';
import {
  AuthorizationAdminForm,
  AuthorizationAdminFormProps,
} from './AuthorizationAdminForm';

interface AuthorizationAdminDialogProps extends AuthorizationAdminFormProps {
  open: boolean;
}

export function AuthorizationAdminDialog({
  open,
  ...props
}: AuthorizationAdminDialogProps) {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={props.onClose} fullWidth maxWidth="sm">
      <DialogTitle>{t('Edit Authorization')}</DialogTitle>
      <AuthorizationAdminForm {...props} />
    </Dialog>
  );
}
