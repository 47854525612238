import { CategoryEvents } from '@livekatsomo/models';
import { Typography } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { ReactNode } from 'react';
import EventsPreviewSwiper from './EventsPreviewSwiper';

/**
 * Props for CategoryEvents component
 */
export type CategoryEventsProps = BoxProps & {
  title?: ReactNode;
  categoryEvents?: CategoryEvents[];
};

/**
 * CategoryEvents component displays a preview of featured events in a swiper
 * @param props - Props for CategoryEvents component
 * @returns  Rendered CategoryEvents component
 */
export function CategoryEventsPreview({
  title,
  categoryEvents,
  sx,
  ...props
}: CategoryEventsProps) {
  if (!categoryEvents || categoryEvents.length === 0) return null;

  return categoryEvents.map(({ categoryName, events }) => (
    <Box sx={{ width: '100%' }} key={categoryName} {...props}>
      {categoryName && (
        <Typography variant="h4" sx={{ px: 2, mt: 2 }}>
          {categoryName}
        </Typography>
      )}
      <EventsPreviewSwiper events={events} />
    </Box>
  ));
}

export default CategoryEventsPreview;
