import { DevTool } from '@hookform/devtools';
import {
  EventAuthorization,
  EventAuthorizationFormValues,
  EventConfigDoc,
  FormDoc,
  eventAuthorizationSchema,
} from '@livekatsomo/models';
import {
  SelectField,
  SwitchField,
  TextField,
} from '@livekatsomo/web/ui-components/react-hook-form-components';
import { zodResolverWithLog } from '@livekatsomo/web/utils';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { z } from 'zod';

/**
 * The form schema for the event authorization card. It extends the `eventAuthorizationSchema` and adds additional fields for password, event IDs, purchase URLs, and authorized domains. It also includes refinement functions to ensure that certain fields are required based on the values of other fields.
 */
export const formSchema = eventAuthorizationSchema
  .extend({
    password: z.string().optional(),
    nettilippuEventId: z.coerce.number().optional(),
    ticketmasterEventId: z.coerce.number().optional(),
    authorizedDomains: z
      .object({
        domainName: z.string(),
      })
      .array()
      .transform((value) => value.map((v) => v.domainName)),
  })
  .refine(
    (data) => {
      if (data.passwordEnabled)
        return data.password?.length && data.password.length === 6;
      return true;
    },
    {
      params: {
        i18n: { key: '6 character password is required' },
      },
      path: ['password'],
    },
  )
  .refine(
    (data) => {
      if (data.nettilippuEnabled) return data.nettilippuEventId !== undefined;
      return true;
    },
    {
      params: {
        i18n: { key: 'Nettilippu event ID is required' },
      },
      path: ['nettilippuEventId'],
    },
  )
  .refine(
    (data) => {
      if (data.nettilippuEnabled)
        return data.purchaseUrl && data.purchaseUrl?.length > 0;
      return true;
    },
    {
      params: {
        i18n: { key: 'Purchase URL is required' },
      },
      path: ['purchaseUrl'],
    },
  )
  .refine(
    (data) => {
      if (data.ticketmasterEnabled)
        return data.ticketmasterEventId !== undefined;
      return true;
    },
    {
      params: {
        i18n: { key: 'Nettilippu event ID is required' },
      },
      path: ['nettilippuEventId'],
    },
  )
  .refine(
    (data) => {
      if (data.ticketmasterEnabled)
        return data.purchaseUrl && data.purchaseUrl?.length > 0;
      return true;
    },
    {
      params: {
        i18n: { key: 'Purchase URL is required' },
      },
      path: ['purchaseUrl'],
    },
  )
  .refine(
    (data) => {
      if (data.domainEnabled) return data.authorizedDomains.length > 0;
      return true;
    },
    {
      params: {
        i18n: { key: 'At least one domain is required' },
      },
      path: ['authorizedDomains'],
    },
  )
  .refine(
    (data) => {
      if (
        !data.surveyFormEnabled ||
        (data.surveyFormId && data.surveyFormId?.length > 0)
      ) {
        return true;
      }
    },
    {
      params: {
        i18n: 'Survey form ID is required if survey form is enabled',
      },
      path: ['surveyFormId'],
    },
  );

export interface AuthorizationAdminFormProps {
  authorization: EventAuthorization;
  eventConfig?: EventConfigDoc | null;
  forms?: FormDoc[] | null;
  onClose: () => void;
  onSubmit: (update: EventAuthorizationFormValues) => Promise<void>;
}
export function AuthorizationAdminForm({
  authorization,
  eventConfig,
  forms,
  onClose,
  onSubmit,
}: AuthorizationAdminFormProps) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const initialValues = {
    passwordEnabled: authorization.passwordEnabled || false,
    password: eventConfig?.password || '',
    domainEnabled: authorization.domainEnabled || false,
    authorizedDomains:
      eventConfig?.authorizedDomains?.map((domain) => ({
        domainName: domain,
      })) || [],
    invitationsEnabled: authorization.invitationsEnabled || false,
    ticketmasterEnabled: authorization.ticketmasterEnabled || false,
    nettilippuEnabled: authorization.nettilippuEnabled || false,
    anonymousUsersEnabled: authorization.anonymousUsersEnabled || false,
    nettilippuEventId: eventConfig?.nettilippuEventId || '',
    ticketmasterEventId: eventConfig?.ticketmasterEventId || '',
    purchaseUrl: authorization.purchaseUrl || '',
    surveyFormEnabled: authorization.surveyFormEnabled || false,
    surveyFormId: authorization.surveyFormId || '',
    hideLoginAndRegister: authorization.hideLoginAndRegister || false,
  };
  const methods = useForm({
    // resolver: zodResolver(formSchema),
    resolver: zodResolverWithLog(formSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  const {
    formState: { isDirty, isSubmitting, isValid },
    reset,
    handleSubmit,
    getValues,
    watch,
    control,
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'authorizedDomains',
    shouldUnregister: true,
  });

  const [
    invitationsEnabled,
    passwordEnabled,
    domainEnabled,
    nettilippuEnabled,
    ticketmasterEnabled,
    surveyFormEnabled,
  ] = watch([
    'invitationsEnabled',
    'passwordEnabled',
    'domainEnabled',
    'nettilippuEnabled',
    'ticketmasterEnabled',
    'surveyFormEnabled',
  ]);

  const authorizationRequired =
    invitationsEnabled ||
    nettilippuEnabled ||
    ticketmasterEnabled ||
    passwordEnabled ||
    surveyFormEnabled;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submit = async (values: any) => {
    try {
      setErrorMessage(null);
      await onSubmit(values);
      reset(getValues());
    } catch (error) {
      if (error instanceof Error) return setErrorMessage(error.message);
      if (typeof error === 'string') return setErrorMessage(error);
      setErrorMessage('Something went wrong');
    }
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submit)} onReset={() => reset()}>
        <DialogContent>
          <DialogContentText>
            {t(
              'Event authorization controls who can access the event. You can use multiple authorization methods at the same time.',
            )}
          </DialogContentText>
          <FormGroup>
            <DevTool control={control} />
            <SwitchField
              name="passwordEnabled"
              label={t('Password Authorization')}
            />
            {passwordEnabled ? (
              <TextField
                margin="dense"
                name="password"
                required
                label={t('password')}
                type="text"
                fullWidth
                disabled={!passwordEnabled}
                variant="standard"
                InputProps={{
                  sx: {},
                  inputProps: {
                    maxLength: 6,
                    style: {
                      fontSize: 'xx-large',
                      width: '6em',
                      textAlign: 'center',
                      letterSpacing: '0.2em',
                    },
                  },
                }}
              />
            ) : null}
          </FormGroup>
          <FormGroup>
            <SwitchField
              name="domainEnabled"
              label={t('Domain Authorization')}
            />
            {domainEnabled ? (
              <>
                <Typography>{t('Authorized Domains')}</Typography>
                <List>
                  {fields.map((item, index) => (
                    <ListItem key={item.id}>
                      <TextField
                        margin="dense"
                        name={`authorizedDomains.${index}.domainName`}
                        required
                        label={t('Authorized domain')}
                        type="text"
                        variant="standard"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label={
                                  t('delete domain') || 'delete domain'
                                }
                                onClick={() => remove(index)}
                                onMouseDown={(event) => event.preventDefault()}
                                edge="end"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ListItem>
                  ))}
                  <Button
                    type="button"
                    onClick={() => append({ domainName: '' })}
                  >
                    {t('New domain')}
                  </Button>
                </List>
              </>
            ) : null}
          </FormGroup>
          <SwitchField
            name="invitationsEnabled"
            fullWidth
            label={t('Invitations')}
          />
          <FormGroup>
            <SwitchField
              name="ticketmasterEnabled"
              fullWidth
              label={t('Ticket Master')}
            />
            {ticketmasterEnabled ? (
              <TextField
                margin="dense"
                name="ticketmasterEventId"
                label={t('Ticketmaster Event ID')}
                required
                type="number"
                fullWidth
                disabled={!ticketmasterEnabled}
                variant="standard"
              />
            ) : null}
          </FormGroup>
          <FormGroup>
            <SwitchField
              name="nettilippuEnabled"
              fullWidth
              label={t('Nettilippu')}
            />
            {nettilippuEnabled ? (
              <TextField
                margin="dense"
                name="nettilippuEventId"
                label={t('Nettilippu Event ID')}
                required
                type="number"
                fullWidth
                disabled={!nettilippuEnabled}
                variant="standard"
              />
            ) : null}
          </FormGroup>
          {nettilippuEnabled || ticketmasterEnabled ? (
            <TextField
              margin="dense"
              name="purchaseUrl"
              label={t('Purchase URL')}
              type="url"
              fullWidth
              variant="standard"
              required
            />
          ) : null}
          {errorMessage ? (
            <Typography color="error">{errorMessage}</Typography>
          ) : null}
          <SwitchField
            name="surveyFormEnabled"
            fullWidth
            label={t('Survey Form')}
          />
          {surveyFormEnabled ? (
            <SelectField
              margin="dense"
              fullWidth
              includeNone
              required
              options={forms || []}
              name={'surveyFormId'}
              label={'Survey Form'}
              optionSelector={(option) => option.id}
              optionViewer={(option) => option.name}
            />
          ) : null}
          <Typography variant="body2" color="text.secondary">
            {t('Additional Options')}
          </Typography>
          <SwitchField
            name="anonymousUsersEnabled"
            fullWidth
            disabled={!authorizationRequired}
            label={t('Anonymous Users Allowed')}
          />

          <SwitchField
            name="hideLoginAndRegister"
            fullWidth
            disabled={!authorizationRequired || domainEnabled}
            label={t('Hide Login and Register')}
          />
        </DialogContent>
        <DialogActions>
          <Button type="reset" disabled={isSubmitting || !isDirty}>
            {t('Reset')}
          </Button>
          <LoadingButton
            type="submit"
            loading={isSubmitting}
            disabled={!isDirty || !isValid}
          >
            {t('Save')}
          </LoadingButton>
          <Button onClick={onClose}>{t('Cancel')}</Button>
        </DialogActions>
      </form>
    </FormProvider>
  );
}
