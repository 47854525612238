import { EventDoc } from '@livekatsomo/models';
import { isLiveRightNow, isVoDRightNow } from '@livekatsomo/shared/utils';
import { useDateFns } from '@livekatsomo/web/hooks';
import { LiveAvatar } from '@livekatsomo/web/ui-components/avatar';
import {
  LiveBadge,
  PublicBadge,
  VodBadge,
  VodEnabledBadge,
} from '@livekatsomo/web/ui-components/layout';
import Folder from '@mui/icons-material/Folder';
import MoreVert from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import NoSsr from '@mui/material/NoSsr';
import Link from 'next/link';

/**
 * Props for the EventListItem component
 */
export interface EventListItemProps {
  href: string; // The URL to link to when the event is clicked
  event: EventDoc; // The event object to display
  showCustomer?: boolean; // Whether to show the customer name in addition to the event name
  showMenu?: boolean; // Whether to show the menu button for the event
  onMenuClick: (
    mouseEvent: React.MouseEvent<HTMLElement>,
    event: EventDoc,
  ) => void; // Function to call when the menu button is clicked
}

/**
 * Renders a single event item in a list of events.
 * @param href - The URL to link to when the event is clicked.
 * @param event - The event object to display.
 * @param showCustomer - Whether to show the customer name in addition to the event name.
 * @param showMenu - Whether to show the menu button for the event.
 * @param onMenuClick - Function to call when the menu button is clicked.
 * @returns A React component that renders a single event item.
 */
export function EventListItem({
  href,
  event,
  showCustomer,
  showMenu,
  onMenuClick,
}: EventListItemProps) {
  const { format } = useDateFns();

  return (
    <ListItemButton
      LinkComponent={Link}
      disabled={!event.customer?.slug}
      href={href}
    >
      <ListItem
        secondaryAction={
          showMenu &&
          onMenuClick && (
            <IconButton
              edge="end"
              onClick={(ev) => {
                ev.preventDefault();
                onMenuClick(ev, event);
              }}
            >
              <MoreVert />
            </IconButton>
          )
        }
      >
        <ListItemAvatar>
          <LiveAvatar name={event.name} asset={event.poster}>
            <Folder />
          </LiveAvatar>
        </ListItemAvatar>
        <ListItemText
          data-chromatic="ignore"
          primary={
            showCustomer && event.customer
              ? `${event.customer?.name} | ${event.name}`
              : event.name
          }
          secondary={
            <NoSsr>
              <span data-chromatic="ignore">
                {format(event.startDate, 'd.M.yyyy HH:mm')} -{' '}
                {format(event.endDate, 'd.M.yyyy HH:mm')}
                <span
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {event.visibility === 'public' ? <PublicBadge /> : null}
                  {event.vodEnabled ? <VodEnabledBadge /> : null}
                  {isLiveRightNow(event) ? (
                    <LiveBadge sx={{ marginLeft: 2 }} />
                  ) : null}
                  {isVoDRightNow(event) ? (
                    <VodBadge sx={{ marginLeft: 2 }} />
                  ) : null}
                </span>
              </span>
            </NoSsr>
          }
        />
      </ListItem>
    </ListItemButton>
  );
}

export default EventListItem;
