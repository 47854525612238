import { ThemeInDocument } from '@livekatsomo/models';
import { Theme } from '@mui/material/styles';
import Head from 'next/head';

/**
 * Props for the CustomFontLoader component.
 */
export interface CustomFontLoaderProps {
  theme: Theme;
  baseTheme?: ThemeInDocument | null;
}

/**
 * Loads custom fonts from Google Fonts API based on the provided theme.
 * @param props - The props for the CustomFontLoader component.
 * @param props.theme - The theme object containing customFonts array.
 * @returns The style element containing the imported font families or null if customFonts array is empty.
 */
export function CustomFontLoader({ theme, baseTheme }: CustomFontLoaderProps) {
  const customFonts = [
    ...(theme.customFonts ?? []),
    ...(baseTheme?.customFonts ?? []),
  ];
  const googleFonturl = customFonts.length
    ? customFonts.map((font) => font).join('&family=')
    : null;

  if (!googleFonturl) {
    return null;
  }

  return (
    <Head>
      <style>{`
        @import url(https://fonts.googleapis.com/css2?family=${googleFonturl}&display=swap)
      `}</style>
    </Head>
  );
}

export default CustomFontLoader;
