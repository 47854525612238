import { EventDoc } from '@livekatsomo/models';
import { useDateFns } from '@livekatsomo/web/hooks';
import { LiveAvatar } from '@livekatsomo/web/ui-components/avatar';
import ClockIcon from '@mui/icons-material/AccessTime';
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import Folder from '@mui/icons-material/Folder';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import NoSsr from '@mui/material/NoSsr';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { Fragment, ReactNode } from 'react';

/**
 * Props for the EventsPreviewList component.
 * @param {ReactNode} title - The title of the list.
 * @param {EventDoc[]} events - An array of event documents to display.
 */
export interface EventsPreviewListProps {
  title?: ReactNode;
  events?: EventDoc[];
}

/**
 * A component that displays a list of event previews.
 * @returns The rendered component.
 */
export function EventsPreviewList({ title, events }: EventsPreviewListProps) {
  const { t } = useTranslation();
  const { format } = useDateFns();
  if (!events || events.length === 0) return null;

  return (
    <>
      {typeof title === 'string' ? (
        <Typography
          variant="h4"
          component="h2"
          sx={{
            alignSelf: 'flex-start',
            textShadow: '0px 0px 10px rgb(0 0 0 / 80%)',
          }}
        >
          {title}
        </Typography>
      ) : (
        title
      )}
      <Box>
        <List>
          {events.map((event, index, items) => (
            <Fragment key={event.id}>
              <li>
                <ListItemButton
                  LinkComponent={Link}
                  href={`${event.customer?.slug}/${event.slug}`}
                >
                  <ListItemAvatar>
                    <LiveAvatar name={event.name} asset={event.poster}>
                      <Folder />
                    </LiveAvatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <>
                        <Typography
                          variant="body1"
                          component="p"
                          color="text.secondary"
                          sx={{ fontWeight: 'bold' }}
                        >
                          {event.name}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="text.secondary"
                          component="p"
                          sx={{ fontWeight: 'bold' }}
                        >
                          {event.customer?.name}
                        </Typography>
                      </>
                    }
                    secondary={
                      <Box
                        component="span"
                        data-chromatic="ignore"
                        sx={{
                          display: 'inline-flex',
                          gap: 2,
                        }}
                      >
                        <Box
                          component="span"
                          sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
                        >
                          <CalendarIcon color="primary" />
                          <NoSsr>
                            <Typography
                              variant="body1"
                              component="time"
                              aria-label={t('start date')}
                            >
                              {format(event.startDate, 'dd.MM.yyyy')}
                            </Typography>
                          </NoSsr>
                        </Box>
                        <Box
                          component="span"
                          sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
                        >
                          <ClockIcon color="primary" />
                          <NoSsr>
                            <Typography
                              variant="body1"
                              component="time"
                              aria-label={t('start time')}
                            >
                              {format(event.startDate, 'hh.mm')}
                            </Typography>
                          </NoSsr>
                        </Box>
                      </Box>
                    }
                  />
                </ListItemButton>
                {index !== items.length - 1 ? (
                  <Divider variant="inset" />
                ) : null}
              </li>
            </Fragment>
          ))}
        </List>
      </Box>
    </>
  );
}

export default EventsPreviewList;
