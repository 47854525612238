/**
 * An array of color types used in the palette.
 *
 * @remarks
 * The color types are 'primary', 'secondary', 'error', 'warning', 'info', and 'success'.
 */
export const paletteColorTypes = [
  'primary',
  'secondary',
  'error',
  'warning',
  'info',
  'success',
] as const;
