import { Event } from '@livekatsomo/models';
import { useDateFns } from '@livekatsomo/web/hooks';
import Box, { BoxProps } from '@mui/material/Box';
import NoSsr from '@mui/material/NoSsr';
import Typography from '@mui/material/Typography';

/**
 * Props for the EventDetailsView component.
 */
export interface EventDetailsViewProps extends BoxProps {
  event: Event;
}

/**
 * Renders the details of an event.
 * @param props - The component props.
 * @param props.event - The event object to display.
 * @returns The rendered component.
 */
export function EventDetailsView({ event, ...props }: EventDetailsViewProps) {
  const { format } = useDateFns();
  return (
    <Box
      data-testid="event-details"
      sx={{
        '.image-wrapper img': { display: 'block' },
        '.image-float-left': {
          marginRight: 2,
        },
        '.image-float-right': {
          marginLeft: 2,
        },
        minWidth: '200px',
      }}
      {...props}
    >
      <Typography
        variant="h5"
        component="h2"
        gutterBottom
        sx={{
          // This is for showing ellipsis when the text is too long
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {event.customer?.name}
      </Typography>
      <Typography
        variant="h4"
        component="h1"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {event.name}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {event.description}
      </Typography>
      <NoSsr>
        <Typography
          variant="subtitle1"
          component="h3"
          gutterBottom
          data-chromatic="ignore"
        >
          {format(event.startDate, 'PPP')}
        </Typography>
      </NoSsr>
    </Box>
  );
}

export default EventDetailsView;
