import { EventComponentName, LayoutConfiguration } from '@livekatsomo/models';
import { defaultEventLayoutConfiguration } from '@livekatsomo/shared/config';
import { RenderLayout } from '@livekatsomo/web/ui-components/layouts';
import { defaultRenderingConfiguration } from '@livekatsomo/web/ui-components/layouts';
import { ReactNode } from 'react';

/**
 * Props for the EventViewContent component.
 */
export interface EventViewContentProps {
  componentConfiguration: Record<EventComponentName, ReactNode>;
  layoutConfiguration?: LayoutConfiguration;
}

/**
 * Renders the content of an event view using the specified component and layout configurations.
 * @param props - The props object containing the component and layout configurations.
 * @returns The rendered event view content.
 */
export function EventViewContent({
  componentConfiguration,
  layoutConfiguration = defaultEventLayoutConfiguration,
}: EventViewContentProps) {
  return (
    <RenderLayout
      id="baseLayout"
      layoutConfiguration={layoutConfiguration}
      components={componentConfiguration}
      renderingConfiguration={defaultRenderingConfiguration}
    />
  );
}

export default EventViewContent;
