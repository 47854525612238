import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { AssetDoc, CustomerDoc, EventDoc } from '@livekatsomo/models';
import {
  AddEventFunction,
  ValidateEventSlugFunction,
} from '@livekatsomo/types';
import { EventForm } from './EventForm';
import { useTranslation } from 'next-i18next';

export interface EventDialogProps extends Omit<DialogProps, 'onSubmit'> {
  event?: EventDoc;
  customer?: CustomerDoc;
  customers?: CustomerDoc[] | null;
  assets?: AssetDoc[];
  onClose: () => void;
  onValidateSlug: ValidateEventSlugFunction;
  onSubmit: AddEventFunction;
}

/**
 * A dialog component for adding or editing an event.
 *
 * @param props - The props for the component.
 * @param props.open - Whether the dialog is open or not.
 * @param props.event - The event to edit, or null if adding a new event.
 * @param props.assets - The list of assets to choose from.
 * @param props.customer - The customer associated with the event.
 * @param props.customers - The list of customers to choose from.
 * @param props.onClose - The function to call when the dialog is closed.
 * @param props.onValidateSlug - The function to call to validate the event slug.
 * @param rest - Any additional props to pass to the component.
 * @returns The JSX element for the component.
 */
export function EventDialog({
  open,
  event,
  assets,
  customer,
  customers,
  onClose,
  onValidateSlug,
  onSubmit,
  ...rest
}: EventDialogProps) {
  const handleClose = () => {
    onClose();
  };
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={handleClose} fullWidth {...rest}>
      <DialogTitle>{event ? t('Edit Event') : t('Add Event')}</DialogTitle>
      <EventForm
        assets={assets}
        event={event}
        onClose={onClose}
        customer={customer}
        customers={customers}
        onValidateSlug={onValidateSlug}
        onSubmit={onSubmit}
      />
    </Dialog>
  );
}

export default EventDialog;
