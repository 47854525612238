import Box from '@mui/material/Box';
import { getByPath } from '@livekatsomo/web/utils';
import { BoxProps } from '@mui/material/Box';

/**
 * Props for the PaletteValues component.
 */
export interface PaletteValuesProps extends BoxProps {
  /**
   * An array of color values in the format of [key, value].
   */
  paletteValues: [string, string][];
  /**
   * The theme object.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: any;
  /**
   * The path of the component.
   */
  path: string;
}

/**
 * Renders a set of color boxes based on the provided palette values.
 * @returns  - The rendered component.
 */
export function PaletteValues({
  paletteValues,
  theme,
  path,
  sx,
  ...props
}: PaletteValuesProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignSelf: 'stretch',
        ...sx,
      }}
      {...props}
    >
      {paletteValues.map(([name, subPath]) => (
        <Box
          key={name}
          aria-label={`${path}.${subPath}`}
          sx={{
            height: '100%',
            width: 15,
            marginRight: 1,
            border: '1px solid grey',
            backgroundColor: getByPath(theme, `${path}.${subPath}`),
          }}
        />
      ))}
    </Box>
  );
}

export default PaletteValues;
