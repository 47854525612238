import { Event } from '@livekatsomo/models';
import { LiveAvatar } from '@livekatsomo/web/ui-components/avatar';
import { Ribbon } from '@livekatsomo/web/ui-components/layout';
import { NextLinkComposed } from '@livekatsomo/web/ui-components/link';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import NoSsr from '@mui/material/NoSsr';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

/**
 * Props for the OldPreviewCard component
 */
export interface OldPreviewCardProps {
  /**
   * The event to display in the card
   */
  event: Event;
  /**
   * The subheader to display in the card
   */
  subHeader: string;
}

/**
 * A card component for displaying event previews
 * @deprecated Use the NewPreviewCard component instead
 */
export function OldPreviewCard({ event, subHeader }: OldPreviewCardProps) {
  const { t } = useTranslation();
  return (
    <Card
      sx={{
        textDecoration: 'none',
        position: 'relative',
        overflow: 'visible',
      }}
    >
      <CardActionArea
        component={NextLinkComposed}
        to={`/${event.customer?.slug}/${event.slug}`}
      >
        <CardMedia
          component="img"
          height="194"
          image={
            event.poster?.downloadUrls?.jpeg200x200 || event.poster?.originalUrl
          }
          alt={event.poster?.alt}
        />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {event.description}
          </Typography>
        </CardContent>
        <CardHeader
          data-chromatic="ignore"
          avatar={<LiveAvatar asset={event.customer?.logo} name={event.name} />}
          title={event.name}
          // Subheader contains the date and time of the event
          // This will be different on server and client side
          // therefore we need to use NoSsr to prevent hydration mismatch
          subheader={<NoSsr>{subHeader}</NoSsr>}
        />
      </CardActionArea>
      {event.customer?.customerType === 'shop' ? (
        <Ribbon position="top-left">{t('Shop')}</Ribbon>
      ) : null}
    </Card>
  );
}

export default OldPreviewCard;
