import { Event } from '@livekatsomo/models';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { useTranslation } from 'next-i18next';

/**
 * Props for the EventLayoutCard component
 */
export interface EventLayoutCardProps {
  /** The event to display */
  event: Event;
  /** Function to edit the event layout */
  onEdit: () => void;
  /** Function to reset the event layout */
  resetLayout: () => void;
}

/**
 * A card component that displays the layout of an event and allows the user to edit or reset the layout.
 */
export function EventLayoutCard({
  event,
  onEdit,
  resetLayout,
}: EventLayoutCardProps) {
  const { t } = useTranslation();
  return (
    <Card aria-labelledby="layout-card-header">
      <CardHeader title={t('Layout')} id="layout-card-header" />
      <CardContent>
        {event.defaultLayout
          ? t('There is custom layout')
          : t('There is no custom layout')}
      </CardContent>
      <CardActions>
        <Button size="small" onClick={onEdit}>
          {t('Edit')}
        </Button>
        {event.defaultLayout ? (
          <Button onClick={resetLayout}>{t('Reset Layout')}</Button>
        ) : null}
      </CardActions>
    </Card>
  );
}

export default EventLayoutCard;
