import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { BoxProps } from '@mui/material/Box';
import { PaletteValues } from './PaletteValues';

/**
 * Props for the PaletteDetails component.
 */
export interface PaletteDetailsProps extends BoxProps {
  /**
   * The title of the palette.
   */
  title: string;
  /**
   * The path of the palette.
   */
  path: string;
  /**
   * An array of palette values.
   */
  paletteValues: [string, string][];
  /**
   * The theme object.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: any;
}

/**
 * Renders a component that displays the details of a palette.
 * @param {PaletteDetailsProps} props - The props for the component.
 * @param props.title - The title of the palette.
 * @param props.path - The path of the palette.
 * @param {[string, string][]} props.paletteValues - The values of the palette.
 * @param {any} props.theme - The theme of the palette.
 * @param {BoxProps} props.sx - The style props for the component.
 * @returns  - The JSX element for the component.
 */
export function PaletteDetails({
  title,
  paletteValues,
  theme,
  path,
  sx,
  ...props
}: PaletteDetailsProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: '1',
        justifyContent: 'space-between',
        ...sx,
      }}
      {...props}
    >
      <Typography
        sx={{
          textTransform: 'capitalize',
        }}
        variant="body2"
        id={`${path}-label`}
      >
        {title}
      </Typography>
      <PaletteValues paletteValues={paletteValues} theme={theme} path={path} />
    </Box>
  );
}

export default PaletteDetails;
