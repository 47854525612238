import { Event } from '@livekatsomo/models';
import { isLiveRightNow, isVoDRightNow } from '@livekatsomo/shared/utils';
import { useDateFns, useSubHeader } from '@livekatsomo/web/hooks';
import {
  LiveBadge,
  Ribbon,
  VodBadge,
} from '@livekatsomo/web/ui-components/layout';
import { NextLinkComposed } from '@livekatsomo/web/ui-components/link';
import ClockIcon from '@mui/icons-material/AccessTime';
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Box, { BoxProps } from '@mui/material/Box';
import Button from '@mui/material/Button';
import NoSsr from '@mui/material/NoSsr';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material/styles';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';

export interface LargePreviewCardProps extends BoxProps {
  event: Event;
  sx?: SxProps;
}

/**
 * A component that displays a large preview card for an event.
 *
 * @param props - The component props.
 * @param props.event - The event to display.
 * @param props.sx - The custom styles to apply to the component.
 * @returns The rendered component.
 */
export function LargePreviewCard({
  event,
  sx,
  ...props
}: LargePreviewCardProps) {
  const { format } = useDateFns();
  const subHeader = useSubHeader(event);
  const { t } = useTranslation();
  if (!event.poster) return null;
  return (
    <Box // sx={{ maxWidth: 345 }}
      component={NextLinkComposed}
      to={`/${event.customer?.slug}/${event.slug}`}
      sx={{
        textDecoration: 'none',
        color: 'inherit',
        position: 'relative',
        overflow: 'visible',
        flex: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'coloumn',
        borderRadius: '16px',
        border: '8px solid transparent',
        boxSizing: 'border-box',
        alignItems: 'stretch',
        justifyContent: 'start',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(sx as any),
      }}
      {...props}
    >
      <Image
        src={
          event.poster?.downloadUrls?.jpeg1000x1000 || event.poster?.originalUrl
        }
        fill={true}
        priority={true}
        blurDataURL={event.poster.blurDataURL}
        placeholder={event.poster?.blurDataURL ? 'blur' : 'empty'}
        alt={event.poster?.alt || 'Poster'}
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          zIndex: 0,
          borderRadius: '8px',
          objectFit: 'cover',
        }}
        sizes="20vw"
      />
      <Box
        sx={{
          padding: 2,
          height: '100%',
          flex: 1,
          display: 'flex',
          maxWidth: '100%',
        }}
      >
        <Box
          sx={{
            zIndex: 1,
            color: 'white',
            background: 'rgba(0, 0, 0, 0.2)',
            backdropFilter: 'blur(5px)',
            borderBottomRightRadius: '8px',
            borderBottomLeftRadius: '8px',
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            borderRadius: '8px',
            flexBasis: ['max-content', 'max-content', '75%'],
            '.MuiTypography-root': {
              textShadow: '0px 0px 10px rgb(0 0 0 / 80%)',
              color: 'white',
            },
            maxWidth: '100%',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              flex: 1,
            }}
          >
            <Typography
              variant="h4"
              component="h3"
              color="text.secondary"
              sx={{
                fontWeight: 'bold',
                overflow: 'hidden',
              }}
            >
              {event.name}
            </Typography>
            <Typography
              variant="h4"
              component="p"
              color="text.secondary"
              sx={{
                fontWeight: 'bold',
                overflow: 'hidden',
              }}
            >
              {event.customer?.name}
            </Typography>
            <Typography
              variant="h6"
              component="h3"
              color="text.secondary"
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {event.description}
            </Typography>

            {isLiveRightNow(event) ? (
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                }}
                data-chromatic="ignore"
              >
                <LiveBadge />
                <NoSsr>
                  {/*
                    Subheader contains the date and time of the event
                    This will be different on server and client side
                    therefore we need to use NoSsr to prevent hydration mismatch
                  */}
                  <Typography variant="body1">{subHeader}</Typography>
                </NoSsr>
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                }}
                data-chromatic="ignore"
              >
                {isVoDRightNow(event) ? <VodBadge /> : null}
                <Box
                  component="span"
                  sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
                >
                  <CalendarIcon color="primary" />
                  <NoSsr>
                    <Typography variant="body1">
                      {format(event.startDate, 'dd.MM.yyyy')}
                    </Typography>
                  </NoSsr>
                </Box>
                <Box
                  component="span"
                  sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
                >
                  <ClockIcon color="primary" />
                  <NoSsr>
                    <Typography variant="body1">
                      {format(event.startDate, 'hh.mm')}
                    </Typography>
                  </NoSsr>
                </Box>
              </Box>
            )}
          </Box>
          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              sx={{
                color: (theme) => theme.palette.common.white,
              }}
            >
              {t('Watch')}
            </Button>
          </Box>
        </Box>
      </Box>
      {event.customer?.customerType === 'shop' ? (
        <Ribbon position="top-right">
          <ShoppingCartIcon />
        </Ribbon>
      ) : null}
      {event.demoEvent ? (
        <Ribbon position="top-right" color="primary">
          {t('Demo')}
        </Ribbon>
      ) : null}
    </Box>
  );
}

export default LargePreviewCard;
