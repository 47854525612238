import { Event } from '@livekatsomo/models';

/**
 * Determines if the Video on Demand (VoD) feature is enabled for an event and if the event is currently available for viewing.
 * @param event - The event object to check for VoD availability.
 * @param now - The current date and time. Defaults to the current system time.
 * @returns A boolean indicating whether the event is currently available for viewing via VoD.
 */
export function isVoDRightNow(event: Event, now = new Date()): boolean {
  const { endDate, vodExpiryDate, vodEnabled } = event;
  if (endDate && vodExpiryDate && vodEnabled) {
    return endDate <= now && vodExpiryDate >= now;
  }
  return false;
}
