import { EventDoc } from '@livekatsomo/models';
import Box, { BoxProps } from '@mui/material/Box';
import NoSsr from '@mui/material/NoSsr';
import { ReactNode, useRef, useState } from 'react';
import { Autoplay, Navigation, Pagination, Virtual } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { LargePreviewCard } from '../EventPreviewCard/LargePreviewCard';

/**
 * Props for FeaturedEventsPreview component
 */
export type FeaturedEventsPreviewProps = BoxProps & {
  title?: ReactNode;
  events?: EventDoc[];
};

/**
 * FeaturedEventsPreview component displays a preview of featured events in a swiper
 * @param props - Props for FeaturedEventsPreview component
 * @returns  Rendered FeaturedEventsPreview component
 */
export function FeaturedEventsPreview({
  title,
  events,
  sx,
  ...props
}: FeaturedEventsPreviewProps) {
  const ref = useRef<HTMLElement>(null);
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);

  if (!events || events.length === 0) return null;

  return (
    <Box
      sx={{
        minWidth: 0,
        display: 'flex',
        position: 'relative',
        '--swiper-pagination-bottom': '0',
        ...sx,
      }}
      {...props}
    >
      <NoSsr>
        <Swiper
          onSwiper={(swiper) => setSwiper(swiper)}
          speed={600}
          virtual={true}
          style={{
            flex: 1,
            // Width is set to 100% to prevent the swiper from overflowing the container
            width: '100%',
          }}
          autoplay={true}
          preventClicks={false}
          modules={[Pagination, Navigation, Autoplay, Virtual]}
          navigation={true}
          pagination={{
            clickable: true,
            el: ref.current,
          }}
          slidesPerView={1}
          effect="fade"
          spaceBetween={50}
        >
          {events &&
            events.map((event, index) => (
              <SwiperSlide key={event.id} virtualIndex={index}>
                <LargePreviewCard
                  event={event}
                  onClick={() => {
                    console.log('clicked swiper slide. Stopping autoplay');
                    swiper?.autoplay.stop();
                  }}
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </NoSsr>
      <Box
        ref={ref}
        sx={{
          position: 'absolute',
          textAlign: 'center',
          transform: 'translateY(100%)',
          display: ['none', 'block'],
        }}
      />
    </Box>
  );
}

export default FeaturedEventsPreview;
