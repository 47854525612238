import { CustomerDoc, EventDoc } from '@livekatsomo/models';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';
import { EventList, EventListProps } from './EventList';

/**
 * Props for the EventsCard component.
 */
export interface EventsProps {
  /**
   * Title of the card.
   */
  title?: ReactNode;
  /**
   * Events to display.
   */
  events: EventListProps['events'];
  /**
   * Customer object.
   */
  customer?: CustomerDoc;
  /**
   * Parent path.
   */
  parentPath?: string;
  /**
   * Function to call when an event is deleted.
   */
  onDelete?: (event: EventDoc) => void;
  /**
   * Function to call when an event is copied.
   */
  onCopy?: (event: EventDoc) => Promise<void>;
  /**
   * Function to call when the "Add new" button is clicked.
   */
  onOpenEventDialog?: () => void;
}

/**
 * A card component that displays a list of events
 * @param props - The props for the component
 * @returns The rendered component
 */
export function EventsCard({
  title,
  events,
  customer,
  parentPath = '',
  onDelete,
  onCopy,
  onOpenEventDialog,
}: EventsProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div">
          {title || t('Events')}
        </Typography>
        <EventList
          events={events}
          parentPath={parentPath}
          onDelete={onDelete}
          onCopy={onCopy}
          customer={customer}
        />
      </CardContent>
      <CardActions>
        {onOpenEventDialog ? (
          <Button
            size="small"
            onClick={onOpenEventDialog}
            aria-label={t('Add new event') || 'Add new event'}
          >
            {t('Add new')}
          </Button>
        ) : null}
      </CardActions>
    </Card>
  );
}

export default EventsCard;
