import { TrackDoc } from '@livekatsomo/models';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useTranslation } from 'next-i18next';
import { NextLinkComposed } from '@livekatsomo/web/ui-components/link';
import { useRouter } from 'next/router';
import { UrlObject } from 'url';

/**
 * Props for the TrackTabs component.
 */
export interface TrackTabsProps {
  /**
   * The ID of the currently selected track.
   */
  trackId: string;
  customerSlug: string;
  eventSlug: string;
  /**
   * An array of tracks to display in the tabs.
   */
  tracks: Pick<TrackDoc, 'id' | 'name'>[];
}

/**
 * Renders a tab navigation component to switch between tracks.
 * @param trackId - The ID of the currently selected track.
 * @param {Array<{id: string, name: string}>} tracks - An array of tracks to display as tabs.
 * @returns  - The rendered TrackTabs component.
 */
export function TrackTabs({
  customerSlug,
  eventSlug,
  trackId,
  tracks,
}: TrackTabsProps) {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <Tabs
      value={trackId}
      variant="scrollable"
      scrollButtons="auto"
      textColor="inherit"
      indicatorColor="secondary"
      aria-label={
        t('Tab navigation to switch between tracks') ||
        'Tab navigation to switch between tracks'
      }
    >
      {tracks.map((tr) => {
        const url: UrlObject = {
          pathname: router.pathname,
          query: { customer: customerSlug, event: eventSlug, trackId: tr.id },
        };

        return (
          <Tab
            key={tr.id}
            to={url}
            label={tr.name}
            value={tr.id}
            component={NextLinkComposed}
          />
        );
      })}
    </Tabs>
  );
}

export default TrackTabs;
