/**
 * @file This file exports the EventsPreviewSwiper component, which displays a swiper component with event preview cards.
 * @module EventsPreviewSwiper
 */

import { EventDoc } from '@livekatsomo/models';
import Box from '@mui/material/Box';
import NoSsr from '@mui/material/NoSsr';
import { useTheme } from '@mui/material/styles';
import { useRef } from 'react';
import { Autoplay, Navigation, Pagination, Virtual } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import EventPreviewCard from '../EventPreviewCard/EventPreviewCard';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
/**
 * The breakpoints for the swiper component.
 * @type
 * @property 600 - The breakpoint for screens with width 600px or less.
 * @property 800 - The breakpoint for screens with width between 601px and 800px.
 * @property 1200 - The breakpoint for screens with width between 801px and 1200px.
 * @property 1536 - The breakpoint for screens with width between 1201px and 1536px.
 */
const breakpoints = {
  600: {
    slidesPerView: 1,
    // spaceBetween: 20,
  },
  800: {
    slidesPerView: 2,
    // spaceBetween: 40,
  },
  1200: {
    slidesPerView: 3,
    // spaceBetween: 50,
  },
  1536: {
    slidesPerView: 3,
    // spaceBetween: 50,
  },
};

/**
 * Props for the EventsPreviewSwiper component.
 */
export interface EventsPreviewSwiperProps {
  events?: EventDoc[];
}

/**
 * The EventsPreviewSwiper component.
 * @param props - The props for the component.
 * @returns The JSX element for the component.
 */
export function EventsPreviewSwiper({ events }: EventsPreviewSwiperProps) {
  const ref = useRef<HTMLElement>(null);

  const theme = useTheme();

  const progressContent = useRef<HTMLSpanElement>(null);
  const progressCircle = useRef<SVGSVGElement>(null);
  const onAutoplayTimeLeft = (
    s: SwiperClass,
    time: number,
    progress: number,
  ) => {
    if (progressCircle.current) {
      progressCircle.current.style.setProperty(
        '--progress',
        (1 - progress).toString(),
      );
    }
    if (progressContent.current) {
      progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    }
  };

  return (
    <Box
      sx={{
        gap: 2,
        '& .swiper-button-disabled': {
          opacity: 0,
        },
        overflow: 'visible',
        position: 'relative',
      }}
    >
      <NoSsr>
        <Swiper
          spaceBetween={Number(theme.spacing(1).slice(0, -2))}
          virtual={{
            enabled: true,
            slides: events || [],
            addSlidesAfter: 1,
            addSlidesBefore: 1,
          }}
          slidesPerView={1}
          style={{
            flex: 1,
            // Width is set to 100% to prevent the swiper from overflowing the container
            width: '100%',
          }}
          autoplay={{
            pauseOnMouseEnter: true,
          }}
          breakpoints={breakpoints}
          preventClicks={false}
          modules={[Pagination, Navigation, Autoplay, Virtual]}
          navigation={true}
          pagination={{
            el: ref.current,
            type: 'bullets',
            clickable: true,
          }}
          onAutoplayTimeLeft={onAutoplayTimeLeft}
        >
          {events &&
            events.map((event, index) => (
              <SwiperSlide key={event.id} virtualIndex={index}>
                <Box sx={{ padding: 1 }}>
                  <EventPreviewCard event={event} type="new" />
                </Box>
              </SwiperSlide>
            ))}
        </Swiper>
        {/* <div
          className="autoplay-progress"
          slot="container-end"
          style={{
            position: 'absolute',
            right: '16px',
            bottom: '16px',
            zIndex: '10',
            width: '48px',
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold',
            color: 'var(--swiper-theme-color)',
          }}
        >
          <svg
            viewBox="0 0 48 48"
            ref={progressCircle}
            style={{
              position: 'absolute',
              left: '0',
              top: '0px',
              zIndex: '10',
              width: '100%',
              height: '100%',
              strokeWidth: '4px',
              stroke: 'var(--swiper-theme-color)',
              fill: 'none',
              strokeDashoffset: 'calc(125.6px * (1 - var(--progress)))',
              strokeDasharray: '125.6',
              transform: 'rotate(-90deg)',
            }}
          >
            <circle cx="24" cy="24" r="20"></circle>
          </svg>
          <span ref={progressContent}></span>
        </div> */}
      </NoSsr>
      <Box
        ref={ref}
        sx={{
          position: 'absolute',
          textAlign: 'center',
          transform: 'translateY(100%)',
          display: ['none', 'block'],
        }}
      />
    </Box>
  );
}

export default EventsPreviewSwiper;
