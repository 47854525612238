import { EventComponentName, Event, TrackDoc } from '@livekatsomo/models';
import { DebugDetails } from '@livekatsomo/web/ui-components/debug-details';
import {
  Breadcrumbs,
  Footer,
  ThemedContainer,
} from '@livekatsomo/web/ui-components/layout';
import { ReactNode } from 'react';
import { EventViewHeader } from './EventViewHeader';

/**
 * Props for the EventView component.
 */
export interface EventViewProps {
  event: Event;
  tracks?: TrackDoc[];
  track?: TrackDoc;
  menuItems?: ReactNode;
  components?: Record<EventComponentName, ReactNode>;
  userMenu: ReactNode;
  children?: ReactNode;
}

/**
 * Renders the view for a single event.
 *
 * @param props - The props object containing the event, tracks, track, children, menuItems, and userMenu.
 * @returns The JSX element representing the event view.
 */
export function EventView({
  event,
  tracks,
  track,
  children,
  menuItems,
  userMenu,
}: EventViewProps) {
  return (
    <>
      <DebugDetails data={{ event, track }} />
      <ThemedContainer>
        <EventViewHeader
          event={event}
          track={track}
          tracks={tracks}
          menuItems={menuItems}
          userMenu={userMenu}
        />
        <Breadcrumbs sx={{ p: 1 }} />
        {children}
        <Footer />
      </ThemedContainer>
    </>
  );
}

export default EventView;
