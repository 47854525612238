import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';

/**
 * Props for the ThemeOverride component.
 */
export interface ThemeOverrideProps {
  label: string;
  children: ReactNode;
}

/**
 * A component that renders a label and its corresponding children with a specific style override.
 * @param label - The label to be displayed.
 * @param children - The children to be displayed with the style override.
 * @returns A JSX element that displays the label and children with the style override.
 */
export function ThemeOverride({ label, children }: ThemeOverrideProps) {
  if (!children) return null;
  return (
    <>
      <Typography
        variant="subtitle1"
        sx={{ flexBasis: '200px' }}
        id={`${label}-label`}
      >
        {label}:
      </Typography>
      <Typography color="textSecondary" aria-labelledby={`${label}-label`}>
        {children}
      </Typography>
    </>
  );
}
