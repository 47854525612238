import { Event, TrackDoc } from '@livekatsomo/models';
import { Header, LiveBadge } from '@livekatsomo/web/ui-components/layout';
import { ListItemLinkButton } from '@livekatsomo/web/ui-components/link';
import { TrackTabs } from '@livekatsomo/web/ui-components/track-tabs';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import { useRouter } from 'next/router';
import { UrlObject } from 'url';

/**
 * Props for the EventViewHeader component
 */
export interface EventViewHeaderProps {
  /** The event to display in the header */
  event: Event;
  /** The track to display in the header */
  track?: TrackDoc;
  /** List of tracks to display in the header */
  tracks?: Pick<TrackDoc, 'id' | 'name'>[];
  /** Additional menu items to display in the header */
  menuItems?: React.ReactNode;
  /** User menu to display in the header */
  userMenu: React.ReactNode;
}

/**
 * Header component for the event view page
 * @param event The event to display in the header
 * @param track The track to display in the header
 * @param tracks List of tracks to display in the header
 * @param menuItems Additional menu items to display in the header
 * @param userMenu User menu to display in the header
 * @returns A Header component with the specified props
 */
export function EventViewHeader({
  event,
  track,
  tracks,
  menuItems,
  userMenu,
}: EventViewHeaderProps) {
  const theme = useTheme();

  let title: string;
  if (theme.katsomo?.header?.hideCustomerName) {
    title = event.name;
  } else {
    title = `${event.customer?.name} | ${event.name}`;
  }

  return (
    <Header
      title={title}
      drawerMenuItems={menuItems}
      userMenu={userMenu}
      badge={track?.mode === 'live' ? <LiveBadge /> : null}
      tabs={
        event.customer?.slug && track && tracks && tracks.length > 1 ? (
          <TrackTabs
            customerSlug={event.customer?.slug}
            eventSlug={event.slug}
            trackId={track?.id}
            tracks={tracks}
          />
        ) : null
      }
      tabsMenu={
        <TabsMenuItems
          customerSlug={event.customer?.slug}
          eventSlug={event.slug}
          trackId={track?.id}
          tracks={tracks}
        />
      }
    />
  );
}

export default EventViewHeader;

function TabsMenuItems({
  customerSlug,
  eventSlug,
  trackId,
  tracks,
}: {
  tracks?: Pick<TrackDoc, 'id' | 'name'>[];
  trackId?: string;
  customerSlug?: string;
  eventSlug?: string;
}) {
  const router = useRouter();
  if (!tracks) return null;
  return (
    <>
      <Divider />
      <List>
        {tracks?.map((tr) => {
          const url: UrlObject = {
            pathname: router.pathname,
            query: { customer: customerSlug, event: eventSlug, trackId: tr.id },
          };

          return (
            <ListItemLinkButton
              key={tr.id}
              to={url}
              selected={tr.id === trackId}
            >
              <ListItemText primary={tr.name} />
            </ListItemLinkButton>
          );
        })}
      </List>
      <Divider />
    </>
  );
}
