import Typography, { TypographyProps } from '@mui/material/Typography';

export function AuthorizationConfigItem({
  label,
  children,
  enabled,
  ...props
}: {
  label: string;
  children: React.ReactNode;
  enabled?: boolean;
} & TypographyProps) {
  return (
    <>
      <Typography
        variant="subtitle1"
        sx={{ flexBasis: '200px' }}
        id={`${label}-label`}
        {...props}
      >
        {label}
        {': '}
      </Typography>
      <Typography
        color={enabled ? 'primary' : 'textSecondary'}
        aria-labelledby={`${label}-label`}
        component="span"
      >
        {children}
      </Typography>
    </>
  );
}
