/**
 * This file exports the `EventAuthorizationCard` component and its props. It also defines the form schema for the event authorization card, which extends the `eventAuthorizationSchema` and adds additional fields for password, event IDs, purchase URLs, and authorized domains. It includes refinement functions to ensure that certain fields are required based on the values of other fields. The component renders a form using `react-hook-form` and `@livekatsomo/web/ui-components/react-hook-form-components` to allow users to edit the event authorization settings. It also displays the current event authorization settings and allows users to update them by submitting the form.
 */
import {
  Event,
  EventAuthorizationFormValues,
  EventConfigDoc,
  FormDoc,
} from '@livekatsomo/models';
import Button from '@mui/material/Button';
import Card, { CardProps } from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { AuthorizationAdminDialog } from './AuthorizationAdminDialog';
import { AuthorizationSettings } from './AuthorizationSettings';

export interface EventAuthorizationCardProps {
  event: Event;
  eventConfig?: EventConfigDoc | null;
  forms?: FormDoc[] | null;
  onUpdate: (update: EventAuthorizationFormValues) => Promise<void>;
}

export function EventAuthorizationCard({
  event,
  eventConfig,
  onUpdate,
  forms,
  ...props
}: CardProps<'div', EventAuthorizationCardProps>) {
  const [open, setOpen] = useState(false);
  const authorization = event.authorization || {};
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (event: EventAuthorizationFormValues) => {
    try {
      await onUpdate(event);
      enqueueSnackbar(t('Authorization updated'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('Failed to update authorization'), {
        variant: 'error',
      });
    }
    setOpen(false);
  };

  const authorizationRequired =
    authorization.invitationsEnabled ||
    authorization.nettilippuEnabled ||
    authorization.ticketmasterEnabled ||
    authorization.passwordEnabled ||
    authorization.surveyFormEnabled;
  return (
    <Card {...props} aria-labelledby="authorization-card-header">
      <CardHeader title={t('Authorization')} id="authorization-card-header" />
      <CardContent>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          {t(
            'Event authorization controls who can access the event. You can use multiple authorization methods at the same time.',
          )}
        </Typography>
        {!authorizationRequired ? (
          <Typography variant="h6" color="text.secondary" gutterBottom>
            {t(
              'No authorization methods are currently enabled. Users will be able to access the event without authorization.',
            )}
          </Typography>
        ) : (
          <AuthorizationSettings
            eventConfig={eventConfig}
            authorization={authorization}
            authorizationRequired={authorizationRequired}
          ></AuthorizationSettings>
        )}
      </CardContent>
      <CardActions>
        <Button onClick={() => setOpen(true)}>{t('Edit')}</Button>
      </CardActions>
      <AuthorizationAdminDialog
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={handleSubmit}
        authorization={authorization}
        eventConfig={eventConfig}
        forms={forms}
      />
    </Card>
  );
}

export default EventAuthorizationCard;
