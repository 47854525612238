import { Event } from '@livekatsomo/models';
import { useDateFns } from '@livekatsomo/web/hooks';
import { Ribbon } from '@livekatsomo/web/ui-components/layout';
import { NextLinkComposed } from '@livekatsomo/web/ui-components/link';
import ClockIcon from '@mui/icons-material/AccessTime';
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import NoSsr from '@mui/material/NoSsr';
import Typography from '@mui/material/Typography';
import Image from 'next/image';

/**
 * Props for the NewPreviewCard component
 */
export interface NewPreviewCardProps {
  event: Event; // The event to display
}

/**
 * A card component for displaying a preview of an event
 * @param {NewPreviewCardProps} props - The props for the component
 * @returns The rendered component
 */
export function NewPreviewCard({ event }: NewPreviewCardProps) {
  const { format } = useDateFns();
  if (!event.poster) return null;
  return (
    <Card
      component={NextLinkComposed}
      to={`/${event.customer?.slug}/${event.slug}`}
      sx={{
        textDecoration: 'none',
        position: 'relative',
        overflow: 'visible',
        height: '200px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        borderRadius: '16px',
        minWidth: '300px',
      }}
    >
      <Image
        src={
          event.poster?.downloadUrls?.jpeg1000x1000 || event.poster?.originalUrl
        }
        fill={true}
        blurDataURL={event.poster.blurDataURL}
        placeholder={event.poster?.blurDataURL ? 'blur' : 'empty'}
        alt={event.poster?.alt || 'Poster'}
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          zIndex: 0,
          borderRadius: '8px',
          objectFit: 'cover',
        }}
        sizes="20vw"
      />
      <Box
        sx={{
          zIndex: 1,
          background: 'rgba(0, 0, 0, 0.2)',
          willChange: 'auto',
          backdropFilter: 'blur(5px)',
          borderBottomRightRadius: '8px',
          borderBottomLeftRadius: '8px',
        }}
      >
        <Box
          sx={{
            padding: 1,
            '.MuiTypography-root': {
              textShadow: '0px 0px 10px rgb(0 0 0 / 80%)',
              color: (theme) => theme.palette.common.white,
            },
          }}
        >
          <Typography
            variant="body1"
            component="h3"
            color="palette.common.white"
            sx={{ fontWeight: 'bold' }}
          >
            {event.name}
          </Typography>
          <Typography
            variant="body1"
            component="p"
            color="palette.common.white"
            sx={{ fontWeight: 'bold' }}
          >
            {event.customer?.name}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
            }}
            data-chromatic="ignore"
          >
            <Box
              component="span"
              sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
            >
              <CalendarIcon color="primary" />
              <NoSsr>
                <Typography
                  variant="body1"
                  component="time"
                  aria-label="start date"
                >
                  {format(event.startDate, 'dd.MM.yyyy')}
                </Typography>
              </NoSsr>
            </Box>
            <Box
              component="span"
              sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
            >
              <ClockIcon color="primary" />
              <NoSsr>
                <Typography
                  variant="body1"
                  component="time"
                  aria-label="start time"
                >
                  {format(event.startDate, 'hh.mm')}
                </Typography>
              </NoSsr>
            </Box>
          </Box>
        </Box>
      </Box>
      {event.customer?.customerType === 'shop' ? (
        <Ribbon position="top-right">
          <ShoppingCartIcon />
        </Ribbon>
      ) : null}
    </Card>
  );
}

export default NewPreviewCard;
