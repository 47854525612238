import { getByPath } from '@livekatsomo/web/utils';
import Typography from '@mui/material/Typography';
import { ThemeOptions } from '@mui/material/styles';
import { PaletteValues } from '../Palette/PaletteValues';

/**
 * Props for overriding the palette values of a theme.
 */
export interface ThemePaletteOverrideProps {
  /** The label for the palette override. */
  label: string;
  /** The path to the palette value in the theme object. */
  path: string;
  /** The theme object to override. */
  theme?: ThemeOptions;
  /** An array of palette values to override. */
  paletteValues: [string, string][];
}

/**
 * Renders a component that overrides a specific palette value in the MUI theme.
 * @param label - The label for the palette value being overridden.
 * @param path - The path to the palette value being overridden.
 * @param theme - The MUI theme object.
 * @param paletteValues - An array of tuples containing the possible values for the palette value being overridden.
 * @returns A JSX element that displays the overridden palette value.
 */
export function ThemePaletteOverride({
  label,
  path,
  theme,
  paletteValues,
}: ThemePaletteOverrideProps) {
  if (!theme) return null;
  const override = getByPath(theme, path);
  if (!override) return null;
  return (
    <>
      <Typography
        variant="subtitle1"
        sx={{ flexBasis: '200px', textTransform: 'capitalize' }}
        id={`${label}-label`}
      >
        {label}:
      </Typography>
      <PaletteValues
        aria-labelledby={`${label}-label`}
        paletteValues={paletteValues}
        theme={theme}
        path={path}
        sx={{ padding: '4px' }}
      />
    </>
  );
}

export default ThemePaletteOverride;
